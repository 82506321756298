@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Noto+Naskh+Arabic:wght@500&family=Gloria+Hallelujah&family=Mynerve&family=Figtree:wght@300&family=Passion+One&family=Spline+Sans&display=swap");
/* 
font-family: 'Amatic SC', cursive;
font-family: 'Gloria Hallelujah', cursive;
font-family: 'Mynerve', cursive;
font-family: 'Passion One', cursive;
font-family: 'Spline Sans', sans-serif;
font-family: 'Noto Naskh Arabic', serif;
*/

.App {
  text-align: center;
}

.highlighted {
  background-color: tan;
}

.container {
  padding: 42;
}

h1 {
  font-family: "Passion One", sans-serif;
  color: black;
}

img {
  width: 100%;
}

.caption {
  font-family: "Mynerve", cursive;
  color: black;
  overflow-wrap: anywhere;
}

.arabic {
  font-family: "Noto Naskh Arabic", serif;
  color: black;
}

.section {
  border-top: 1px solid rgb(214, 214, 214);
  border-bottom: 1px solid rgb(214, 214, 214);
  width: 100%;
  min-height: 300px;
  height: fit-content;
  /* padding: 12px; */
  /* clear: both; */
}

.text {
  font-family: "Figtree", cursive;
  font-size: 25px;
  text-align: justify;
  overflow-wrap: anywhere;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #f7f6f6; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeInUp {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 1s ease, transform 1s ease;
  font-family: "Figtree", cursive;
  font-size: 25px;
  text-align: justify;
}

.fadeInUp.visible {
  opacity: 1;
  transform: translateY(0);
}

.magic-input {
  border: 2px solid #ccc;
  transition: border-color 0.3s ease;
  width: 300px;
  height: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #3b3b3b;
  text-transform: uppercase;
}

.magic-input.animate {
  border-color: green;
  animation: fadeOut 1.5s forwards;
}

@keyframes fadeOut {
  from {
    border-color: green;
    opacity: 1;
  }
  to {
    border-color: green;
    opacity: 0;
  }
}

.redacted {
  background-color: black;
}

.redacted.animate {
  animation: redactedFade 1.5s forwards;
}

@keyframes redactedFade {
  from {
    background-color: black;
  }
  to {
    background-color: transparent;
  }
}
